import { z } from "zod";

//TODO(Danny): KILL this endpoint after merging
export const zipcodeAuth = {
  input: z.object({
    zipcode: z.string(),
    orderId: z.string(),
    baseUrl: z.string(),
  }),
  output: z.object({
    customerAuthToken: z.string().nullish(),
    customerName: z.string().nullish(),
    orderId: z.string().nullish(),
    maskedEmail: z.string().nullish(), // If this is present, it means the order has an email associated with it and we have sent them a one-time login link
  }),
};
